import React from "react";
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ReactComponent as TypeORMIcon } from '../../Assets/typeorm.svg';
import { ReactComponent as RamdaIcon } from '../../Assets/ramda.svg';
import NxIcon from '../../Assets/nx.png';
import {
  SiTypescript,
  SiMobx,
  SiRedux,
  SiAmazonaws,
  SiDocker,
  SiJest,
  SiPostgresql,
  SiMicrosoftazure,
  SiAzuredevops,
  SiShopify,
  SiTailwindcss,
  SiElastic,
  SiMui,
  SiBootstrap,
  SiExpo,
  SiMongodb,
  SiFirebase,
  SiMysql,
  SiPrometheus,
  SiVisualstudiocode,
  SiPostman,
  SiAndroidstudio,
  SiJenkins,
  SiCircleci,
  SiVercel,
  SiXcode,
  SiContentful,
  SiOpenai,
  SiAxios,
  SiExpress,
  SiGooglecloud,
  SiWasabi,
  SiHeroku,
  SiD3Dotjs
} from "react-icons/si";
import { AiFillHtml5 } from "react-icons/ai";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiCss3,
  DiRedis,
} from "react-icons/di";
import { TbBrandNextjs, TbBrandThreejs } from "react-icons/tb";
import { GrGraphQl } from "react-icons/gr";
import { DiGit } from "react-icons/di";

function Techstack() {
  return (
    <>
    <h5 style={{ marginTop: "20px"}}>
      <strong className="purple">Frontend </strong>Technologies
    </h5>
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>JavaScript</Tooltip>}
        >
          <Col xs={3} md={2} className="tech-icons">
            <DiJavascript1 />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>TypeScript</Tooltip>}
        >
          <Col xs={3} md={2} className="tech-icons">
            <SiTypescript />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>React / React Native</Tooltip>}
        >
          <Col xs={3} md={2} className="tech-icons">
            <DiReact />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Next JS</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <TbBrandNextjs />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>MobX</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiMobx />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Redux</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiRedux />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>HTML</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <AiFillHtml5 />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>CSS</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <DiCss3 />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Tailwind CSS</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiTailwindcss />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Material-UI</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiMui />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Bootstrap</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiBootstrap />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Expo</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiExpo />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Three.js</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <TbBrandThreejs />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>D3.js</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiD3Dotjs />
          </Col>
        </OverlayTrigger>

        <h5 style={{ marginTop: "20px"}}>
          <strong className="purple">Backend </strong>Technologies
        </h5>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Node JS</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <DiNodejs />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>TypeORM</Tooltip>}
        >
          <Col xs={3} md={2} className="tech-icons">
            <TypeORMIcon />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>GraphQL</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <GrGraphQl />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Express</Tooltip>}
        >
          <Col xs={3} md={2} className="tech-icons">
            <SiExpress />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>PostgreSQL</Tooltip>}
        >
          <Col xs={3} md={2} className="tech-icons">
            <SiPostgresql />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>MySQL</Tooltip>}
        >
          <Col xs={3} md={2} className="tech-icons">
            <SiMysql />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Firebase</Tooltip>}
        >
          <Col xs={3} md={2} className="tech-icons">
            <SiFirebase />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>MongoDB</Tooltip>}
        >
          <Col xs={3} md={2} className="tech-icons">
            <SiMongodb />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Axios</Tooltip>}
        >
          <Col xs={3} md={2} className="tech-icons">
            <SiAxios />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Elastic Search</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiElastic />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Redis</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <DiRedis />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Ramda</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <RamdaIcon />
          </Col>
        </OverlayTrigger>

        <h5 style={{ marginTop: "20px"}}>
          <strong className="purple">Cloud </strong>Services and Deployment
        </h5>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Azure</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiMicrosoftazure/>
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Azure Devops</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiAzuredevops/>
          </Col>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Amazon Web Services </Tooltip>}
        >
          <Col xs={3} md={2} className="tech-icons">
            <SiAmazonaws />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Cloud Firestore</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiGooglecloud/>
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Wasabi Cloud</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiWasabi/>
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Docker</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiDocker />
          </Col>
        </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip>Vercel</Tooltip>}>
        <Col xs={3} md={2} className="tech-icons">
          <SiVercel />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip>Heroku</Tooltip>}>
        <Col xs={3} md={2} className="tech-icons">
          <SiHeroku />
        </Col>
      </OverlayTrigger>

      <h5 style={{ marginTop: "20px"}}>
        <strong className="purple">Testing </strong>and Quality Assurance
      </h5>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Jest</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiJest />
          </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Jenkins</Tooltip>}>
        <Col xs={3} md={2} className="tech-icons">
          <SiJenkins />
        </Col>
        </OverlayTrigger>

        <OverlayTrigger placement="bottom" overlay={<Tooltip>Circleci</Tooltip>}>
        <Col xs={3} md={2} className="tech-icons">
          <SiCircleci />
        </Col>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Prometheus</Tooltip>}
        >
          <Col xs={3} md={2} className="tech-icons">
            <SiPrometheus />
          </Col>
        </OverlayTrigger>

        <h5 style={{ marginTop: "20px"}}>
        <strong className="purple">Development Tools </strong>and IDEs
        </h5>

        <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>Visual Studio Code</Tooltip>}
      >
        <Col xs={3} md={2} className="tech-icons">
          <SiVisualstudiocode />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>X Code</Tooltip>}
      >
        <Col xs={3} md={2} className="tech-icons">
          <SiXcode />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>Android Studio</Tooltip>}
      >
        <Col xs={3} md={2} className="tech-icons">
          <SiAndroidstudio />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip>Git</Tooltip>}>
        <Col xs={3} md={2} className="tech-icons">
          <DiGit />
        </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip>Postman</Tooltip>}>
        <Col xs={3} md={2} className="tech-icons">
          <SiPostman />
        </Col>
        </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip>NX</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
          <img src={NxIcon} alt="Nx Icon" style={{ backgroundColor: '#fff', height: '1.5em' }} />
          </Col>
      </OverlayTrigger>

      <h5 style={{ marginTop: "20px"}}>
        <strong className="purple">APIs </strong>and Integration
      </h5>

      <OverlayTrigger placement="bottom" overlay={<Tooltip>Contentful CMS</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiContentful />
          </Col>
      </OverlayTrigger>
      
      <OverlayTrigger placement="bottom" overlay={<Tooltip>OpenAI API</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiOpenai />
          </Col>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip>Shopify</Tooltip>}>
          <Col xs={3} md={2} className="tech-icons">
            <SiShopify />
          </Col>
      </OverlayTrigger>
    </Row>
    </>
  );
}

export default Techstack;
